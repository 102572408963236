import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Yash Jain </span>
            from <span className="purple"> Agra, India.</span>
            <br />I am currently employed as a software developer at{" "}
            <a
              className="purple"
              href="https://www.chittorgarh.com/"
              style={{ textDecoration: "none" }}
            >
              Chittorgarh Infotech Pvt. Ltd.
            </a>
            <br />
            I am completing my Bachelors in Arts at BU Jhansi.
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Writing Tech Blogs
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            " I love connecting with different people so if you want to say hi,
            I'll be happy to meet you more! "{" "}
          </p>
          <footer className="blockquote-footer">Yash</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
