import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/Project-4.jpeg";

import editor from "../../Assets/Projects/Project-3.png";
import chatify from "../../Assets/Projects/project-2.png";
import suicide from "../../Assets/Projects/Project-5.png";
import bitsOfCode from "../../Assets/Projects/project-1.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="NETHUB"
              description="Movies & TV Shows website provides users with a platform to browse and explore a vast collection of movies and TV shows."
              ghLink="https://github.com/yashxjain/Nethub"
              demoLink="https://nethub-topaz.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="ACE FASHION"
              description="This is ecommerce brand specially for women where you can find products related to SKIN CARE, BEAUTY, HOME DECORE, and many more"
              ghLink="https://github.com/yashxjain/greedy-cream-9934"
              demoLink="https://guileless-pastelito-b0fbc7.netlify.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="GDSC BU"
              description="Official website of Google Developer Student Clubs (GDSC) at Bundelkhand University. Hub for information about our club, events, projects, & resources."
              ghLink="https://github.com/GDSC23BundelkhandUniversity/GDSC23BundelkhandUniversity.github.io"
              demoLink="https://gdsc23bundelkhanduniversity.github.io/"
            />
          </Col>
          <h1 className="project-heading">
            Working <strong className="purple">On </strong>
          </h1>
          <p style={{ color: "white" }}>
            Here are a few projects I'am working and looking for contributors.
          </p>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Cyberfraud"
              description=""
              ghLink="https://github.com/yashxjain/Cyberfraud_"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Employee Report"
              description=""
              ghLink="https://github.com/yashxjain/Employee_Report"
              
            />
          </Col>

          
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
